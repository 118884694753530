import type { Workbook } from '@mathflat/domain/@entities/(Content)/Workbook/domain.ts'
import type { MathflatApi } from '@mathflat/domain/@entities/Academy/api'
import { Entity as CurriculumEntity } from '@mathflat/domain/@entities/Curriculum/dto'
import { SCHOOL_TYPE_KO_ABBR } from '@mathflat/domain/@entities/SchoolSystem/constants'
import { Entity as StudentEntity } from '@mathflat/domain/@entities/Student/dto'
import { isDevelopMode } from '@mathflat/shared/@common/utils/dev'
import DynamicRepo from '@mathflat/shared/@modules/mobx/repository/dynamicRepo.service'
import ServiceRepo from '@mathflat/shared/@modules/mobx/repository/repo.service'
import { makeAutoObservable, observable, runInAction } from 'mobx'

import { studentApi } from '../api/studentApi'
import { envUtils } from '../utils/envUtils'
import { localStorageService } from './storage.service'

export const serviceRepo = new ServiceRepo()
export const dynamicRepo = new DynamicRepo()

class CommonRepo {
  private _academy: MathflatApi.Response.Academy | null = null
  private _student: StudentEntity.Student | null = null
  private _curriculumTreeColl: CurriculumEntity.CurriculumTreeColl | null = null
  private _studentChallengeFilterOption: {
    workbooks: Workbook[]
  } | null = null

  constructor() {
    makeAutoObservable<
      this,
      '_academy' | '_student' | '_curriculumTreeColl' | '_studentChallengeFilterOption'
    >(this, {
      _academy: observable,
      _student: observable,
      _curriculumTreeColl: observable,
      _studentChallengeFilterOption: observable,
    })
    if (localStorage.getItem('studentId')) {
      const studentId = localStorage.getItem('studentId') as StudentEntity.Student['id']
      this._student = new StudentEntity.Student({ id: studentId })
    }
  }
  setStudentChallengeFilter = (workbooks: Workbook[]) => {
    this._studentChallengeFilterOption = {
      workbooks,
    }
  }

  get academyLogoUrl() {
    const DEFAULT_MATHFLAT_LOGO_URL =
      'https://mathflat-user-uploads-dev.mathflat.com/logo/Default.png'
    const DEFAULT_SCHOOLFLAT_LOGO_URL =
      'https://mathflat-frontend-asset.s3.ap-northeast-2.amazonaws.com/teacher/assets/schoolflat_logo.png'

    if (commonRepo.isSchoolflat) {
      if (this._academy?.logoUrl === DEFAULT_MATHFLAT_LOGO_URL) {
        return DEFAULT_SCHOOLFLAT_LOGO_URL
      }
    }
    return this._academy?.logoUrl
  }

  setAcademy = (academy: CommonRepo['_academy']) => {
    this._academy = academy
  }

  setStudent = (student: StudentEntity.Student) => {
    // TODO: @회준쌤 Schema validation?
    this._student = new StudentEntity.Student({
      ...this._student,
      ...student,
      appSetting: new StudentEntity.StudentAppSetting(student.appSetting),
    })
  }

  setCurriculumTree = (curriculumTrees: CurriculumEntity.CurriculumTree[]) => {
    this._curriculumTreeColl = new CurriculumEntity.CurriculumTreeColl(curriculumTrees)
  }

  get studentId() {
    return this._student?.id
  }

  get student() {
    this._student?.schoolType
    return this._student
  }

  get studentSchoolTypeWithGrade() {
    const student = this._student
    if (student?.schoolType) {
      return SCHOOL_TYPE_KO_ABBR[student.schoolType] + student.grade
    }
    return ''
  }

  get studentAppSetting() {
    return this._student?.appSetting
  }

  get curriculumTreeColl() {
    return this._curriculumTreeColl
  }

  get studentDefaultCurriculumKey() {
    if (!this.student || !this.student.grade) return null
    const schoolType = this.student.schoolType
    // remote storage에 저장된 key가 없고, 학생이 고등학생이라면 `고등수학(상)`의 key를 사용한다.
    if (schoolType === 'HIGH') {
      const curriculum = this.curriculumTreeColl?.toArr.find(
        ({ korSchoolGradeSemester }) => korSchoolGradeSemester === `고등수학(상)`,
      )
      return curriculum?.trieKey ?? null
    }

    if (schoolType === 'ELEMENTARY' || schoolType === 'MIDDLE') {
      const curriculum = this.curriculumTreeColl?.toArr.find(
        ({ korSchoolGradeSemester, revision }) =>
          revision !== 'CURRICULUM_22' &&
          korSchoolGradeSemester === `${SCHOOL_TYPE_KO_ABBR[schoolType]}${this.student!.grade}-1`,
      )
      return curriculum?.trieKey ?? null
    }
  }

  get studentCurriculumInfo() {
    if (!this.studentDefaultCurriculumKey) return null

    const curriculumInfo = this.curriculumTreeColl?.getCurriculumInfoByCurriculumKey(
      this.studentDefaultCurriculumKey,
    )

    return curriculumInfo
  }

  get isSchoolflat() {
    return envUtils.isSchool
  }

  get host() {
    if (this.isSchoolflat) {
      return 'schoolflat.com'
    }
    return 'mathflat.com'
  }

  get appKorName() {
    if (this.isSchoolflat) {
      return '스쿨플랫'
    }
    return '매쓰플랫'
  }

  get agencyNameInKor() {
    if (this.isSchoolflat) {
      return '학교'
    }
    return '학원'
  }

  get Ai튜터_사용가능_여부() {
    return (
      this.studentAppSetting?.개념힌트_사용 ||
      this.studentAppSetting?.풀이확인_사용 ||
      this.studentAppSetting?.풀이분석_사용
    )
  }

  async loadUser() {
    const storedUserId = localStorageService.user.studentId
    if (storedUserId) {
      const { data: fetchedStudentInfo } = await studentApi.getStudentById(storedUserId)
      const {
        일반채점_채점전정답공개,
        채점후정답해설공개,
        채점전문풀동공개,
        채점후문풀동공개,
        lectureService,
        개념힌트_사용,
        풀이분석_사용,
        풀이확인_사용,
      } = await studentApi.getStudentAppSettings(storedUserId)

      runInAction(() => {
        this._student = new StudentEntity.Student({
          ...fetchedStudentInfo,
          appSetting: {
            일반채점_채점전정답공개,
            채점후정답해설공개,
            채점전문풀동공개,
            채점후문풀동공개,
            lectureService,
            개념힌트_사용,
            풀이분석_사용,
            풀이확인_사용,
          },
        })
      })
    }
  }
}

export const commonRepo = new CommonRepo()

if (isDevelopMode) globalThis._commonRepo = commonRepo
